<template>
    <div class="app-container">
        <el-input
            v-model="queryInfo.query"
            clearable
            size="small"
            placeholder="输入名称搜索"
            style="width: 200px;"
            class="filter-item"
            @keyup.enter.native="crud.toQuery"
        />
        <span>
            <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="getTableData()">搜索</el-button>
        </span>
        <div  class="crdc-opts">
          <span>
            <slot name="left" />
            <el-button
              class="filter-item"
              size="mini"
              type="primary"
              icon="el-icon-plus"
              @click="add()"
            >
              新增
            </el-button>
            <!-- <el-button
              class="filter-item"
              size="mini"
              type="success"
              icon="el-icon-edit"
            >
              修改
            </el-button>
            <el-button
              slot="reference"
              class="filter-item"
              type="danger"
              icon="el-icon-delete"
              size="mini"
            >
              删除
            </el-button> -->
          </span>
          <!--右侧-->
            <slot name="right" />
          <el-button-group class="crud-opts-right">
              <el-button
                size="mini"
                icon="el-icon-refresh"
                @click="getTableData()"
              />
            </el-button-group>
        </div>
        <el-table
        :data="tableData"  highlight-current-row
        @current-change="companyCurrentChange"
        border stripe
        style="width: 100%">
        <el-table-column
          fixed
          prop="id"
          label="横幅ID"
          min-width="80">
        </el-table-column>
        <el-table-column
          fixed
          prop="img_TITLE"
          label="海报标题"
          min-width="150">
        </el-table-column>
        <el-table-column
          prop="is_VALID_NAME"
          label="是否有效"
          min-width="50">
        </el-table-column>
        <el-table-column
          prop="img_ORDER"
          label="排序"
          :show-overflow-tooltip="true"
          min-width="180">
        </el-table-column>
        <el-table-column
          label="操作" align="center"
          min-width="180">
          <template slot-scope="scope">
            <el-button type="primary"  icon="el-icon-edit" size="mini" @click="handleEdit(scope.$index, scope.row)" ></el-button>
            <el-button type="danger"  icon="el-icon-delete" size="mini" @click="deleteFrom(scope.$index, scope.row)"></el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-card>
          <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pagenum"
        :page-sizes="[2, 5, 10, 15]"
        :page-size="queryInfo.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totle"
      ></el-pagination>
      <el-dialog append-to-body  title="广告横幅" :visible.sync="addDialogVisible" width="35%" @close='resetForm'>
          <el-form ref="form" :rules="rules" :model="form"  label-width="80px" class="demo-ruleForm">
            <div>
            <el-form-item label="标题" prop="img_TITLE" >
              <el-col :span="11">
                <el-input v-model="form.img_TITLE"></el-input>
               </el-col>
            </el-form-item>
            <el-form-item label="是否有效" prop="is_VALID">
              <el-select v-model="form.is_VALID"  placeholder="请选择">
                <el-option
                  v-for="item in isOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="排序" prop="img_ORDER">
              <el-col :span="5">
                <el-input v-model="form.img_ORDER"></el-input>
               </el-col>
            </el-form-item>
            <el-form-item  label="展示图片" >
              <el-upload
                class="upload-demo"
                ref="upload"
                action="#"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :on-change="imgChange"
                :limit="1"
                :file-list="fileList"
                accept=".jpg, .png"
                :auto-upload="false">
                <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                <span style="margin-left:10px">图片格式为jpg、png</span>
                <!-- <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div> -->
              </el-upload>
            </el-form-item>
            <el-form-item  label="海报图片" >
              <el-upload
                class="upload-demo"
                ref="upload"
                action="#"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :on-change="reportChange"
                :limit="1"
                :file-list="reportFileList"
                accept=".jpg, .png"
                :auto-upload="false">
                <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                <span style="margin-left:10px">图片格式为jpg、png</span>
                <!-- <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div> -->
              </el-upload>
            </el-form-item>
            </div>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button type="text" @click="resetForm()" >取消</el-button>
            <el-button v-if="isAdd == true"  type="primary" @click="submitFrom('form')">确认</el-button>
            <el-button v-if="isAdd == false" type="primary" @click="updateFrom('form')">修改</el-button>
          </div>
      </el-dialog>
      <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt="">
      </el-dialog>
      </el-card>
    </div>
</template>
<script>
import axios from 'axios'
var mytoken = window.sessionStorage.getItem('token')
export default {
  name: 'Banner',
  created () { //  初始化
    this.getTableData()
  },
  methods: {
    async getTableData () {
      const { data: res } = await this.$http.get('query/banner', {
        params: this.queryInfo
      })
      this.tableData = res.list
      this.totle = res.total
    },
    companyCurrentChange (val) {
    },
    add () {
      this.addDialogVisible = true
      this.isAdd = true
    },
    handleEdit (index, row) {
      this.addDialogVisible = true
      this.form.id = row.id
      this.form.img_TITLE = row.img_TITLE
      this.form.img_ORDER = row.img_ORDER
      this.form.is_VALID = row.is_VALID + ''
      this.isAdd = false
    },
    resetForm () {
      this.addDialogVisible = false
      this.form.img_TITLE = ''
      this.form.img_ORDER = ''
      this.form.is_VALID = ''
    },
    submitFrom (formName) {
      var isPass = false
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.form.fileList === undefined || this.form.fileList.length < 0 || this.form.reportFileList === undefined || this.form.reportFileList.length < 0) {
            this.$message({
              message: '文件不能为空',
              type: 'warning'
            })
          } else {
            isPass = true
          }
        }
      })
      if (isPass) {
        const url = axios.defaults.baseURL
        const formData = new FormData()
        let path = ''
        // 不含图片时和含图片请求分开
        if (this.form.fileList.length > 0) {
          path = '/upload/banner'
          formData.append('file', this.form.fileList[0].raw)
          formData.append('repFile', this.form.reportFileList[0].raw)
        } else {
          path = '/insert/banner'
        }
        formData.append('img_TITLE', this.form.img_TITLE)
        formData.append('img_ORDER', this.form.img_ORDER)
        formData.append('is_VALID', this.form.is_VALID)
        axios.post(url + path, formData, { Authorization: mytoken, 'Content-Type': 'multipart/form-data' }
        ).then(res => {
          this.addDialogVisible = false
          this.getTableData()
        })
      }
    },
    async updateFrom (formName) {
      var isPass = false
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.form.fileList === undefined || this.form.fileList.length < 0 || this.form.reportFileList === undefined || this.form.reportFileList.length < 0) {
            this.$message({
              message: '文件不能为空',
              type: 'warning'
            })
          } else {
            isPass = true
          }
        }
      })
      if (isPass) {
        const url = axios.defaults.baseURL
        const formData = new FormData()
        let path = ''
        // 不含图片时和含图片请求分开
        if (this.form.fileList.length > 0) {
          path = '/upload/update/banner'
          formData.append('file', this.form.fileList[0].raw)
          formData.append('repFile', this.form.reportFileList[0].raw)
        } else {
          path = '/update/banner'
        }
        formData.append('id', this.form.id)
        formData.append('img_TITLE', this.form.img_TITLE)
        formData.append('img_ORDER', this.form.img_ORDER)
        formData.append('is_VALID', this.form.is_VALID)
        axios.post(url + path, formData, { Authorization: mytoken, 'Content-Type': 'multipart/form-data' }
        ).then(res => {
          this.addDialogVisible = false
          this.getTableData()
        })
      }
    },
    async deleteFrom (index, row) {
      await this.$http.post('delete/banner', row)
      this.getTableData()
    },
    // 监听 pagesize改变的事件
    handleSizeChange (newSize) {
      this.queryInfo.pagesize = newSize
      this.getTableData()
    },
    // 监听 页码值 改变事件
    handleCurrentChange (newSize) {
      this.queryInfo.pagenum = newSize
      this.getTableData()
    },
    handleRemove (file) {
    },
    handlePreview (file) {
    },
    imgChange (file, fileList) {
      // this.form.name = file.name
      this.form.fileList = fileList
    },
    reportChange (file, fileList) {
      // this.form.name = file.name
      this.form.reportFileList = fileList
    }
  },
  data () {
    return {
      queryInfo: {
        query: '',
        pagenum: 1,
        // 每页显示多少数据
        pagesize: 10
      },
      totle: 0,
      form: {
        img_TITLE: '',
        img_ORDER: '',
        is_VALID: '',
        id: '',
        img: '',
        file: '',
        name: ''
      },
      isOptions: [{
        value: '1',
        label: '是'
      }, {
        value: '0',
        label: '否'
      }],
      tableData: [],
      addDialogVisible: false,
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      // fileList: [{
      //   name: 'food.jpeg', url: 'http://localhost:8001/crdc/static/images/1.jpg'
      // }]
      fileList: [],
      reportFileList: [],
      url: axios.defaults.baseURL + '/upload/banner',
      isAdd: '',
      rules: {
        img_TITLE: [
          { required: true, message: ' ', trigger: 'change' }
        ],
        is_VALID: [
          { required: true, message: ' ', trigger: 'change' }
        ],
        img_ORDER: [
          { required: true, message: ' ', trigger: 'change' }
        ]
      }
    }
  }
}
</script>
<style>
.crdc-opts {
  padding: 6px 0;
  display: -webkit-flex;
  display: flex;
  align-items: center;
}
.crdc-opts .crud-opts-right {
  margin-left: auto;
}
</style>
